<template>
    <mercur-card class="full-height-layout fill mx-4 mb-3">
        <grid-header :quickSearch.sync="filters.search">
            Auto-updated imported products
        </grid-header>

        <data-table
            class="fill full-height-layout border"
            :options="gridOptions"
            v-if="url"
            ref="grid"
            :url="url"
            :quickSearch="filters.search"
        ></data-table>
    </mercur-card>
</template>

<script>
import CONFIG from '@root/config'
import DataTable from '../../components/DataTable'
import GridHeader from '../../components/GridHeader'

export default {
    name: 'AutoUpdatedProductsOverview',
    components: { GridHeader, DataTable },
    data () {
        return {
            isLoading: false,
            filters: {
                search: null,
            },
            gridOptions: {
                columns: {
                    'Supplier': {
                        field: 'supplierName',
                        sortable: true,
                        filter: 'agTextColumnFilter',
                        link: (value, data) => {
                            return {
                                name: 'SupplierImportedProducts',
                                params: {
                                    supplierId: data.supplierId,
                                },
                            }
                        },
                    },
                    'Product name': {
                        field: 'productName',
                        sortable: true,
                        filter: 'agTextColumnFilter',
                        link: (value, data) => {
                            return {
                                name: 'SupplierImportedProductEdit',
                                params: {
                                    supplierId: data.supplierId,
                                    supplierProductImportedId: data.supplierProductImportedId,
                                },
                            }
                        },
                    },
                    'Status': {
                        field: 'productImportStatus',
                        sortable: false,
                        statusChip: true,
                    },
                    'Updated': {
                        field: 'dateUpdated',
                        sortable: true,
                    },
                },
                sortModel: [
                    {
                        colId: 'dateUpdated',
                        sort: 'desc',
                    },
                ],
                quickSearchColumns: ['productName'],
            },
            url: CONFIG.API.ROUTES.PRODUCTS.AUTO_UPDATED.OVERVIEW,
        }
    },
}
</script>
